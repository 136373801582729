export enum ProductionType {
    Other = 0,
    Musical = 1,
    Play = 2,
    Opera = 3,
    Dance = 4,
    Concert = 5
}

export const getProductionType = (status: ProductionType) => {
    switch (status) {
        case ProductionType.Other:
            return { name: 'Other', class: 'warning' }
        case ProductionType.Musical:
            return { name: 'Musical', class: 'warning' }
        case ProductionType.Play:
            return { name: 'Play', class: 'warning' }
        case ProductionType.Opera:
            return { name: 'Opera', class: 'warning' }
        case ProductionType.Dance:
            return { name: 'Dance', class: 'warning' }
        case ProductionType.Concert:
            return { name: 'Concert', class: 'warning' }
        default:
            return { name: 'Unknown', class: 'warning' }
    }
}
